<template>
  <div class="elv-ledger-journal-type-detail-page">
    <Header
      ref="headerRef"
      type="GENERAL_LEDGER"
      :title="t('menus.ledger')"
      updated-on=""
      :show-regenerate-button="false"
      :show-regenerate="false"
    />

    <HeaderTabs />
    <div v-loading="loading" class="elv-ledger-journal-type-detail-content">
      <AccountBalanceScreening ref="screeningRef" table-type="journalType" @onAddNew="onAddNewJournalType" />

      <TableList
        ref="journalTypeTableRef"
        :table-data="tableData"
        :table-loading="loading"
        :table-height="tableHeight"
        :journal-type-params="journalTypeParams"
        @onChangePage="onChangePage"
        @onChangePageSize="onChangePageSize"
        @onResetList="fetchJournalTypeList()"
        @onEditJournalType="onEditJournalType"
      />
      <JournalTypeFormDialog
        ref="journalTypeFormDialogRef"
        :model="journalTypeFormDialogModel"
        :current-data="currentData"
        @onResetList="fetchJournalTypeList()"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import Header from '../../components/Header.vue'
import TableList from './components/TableList.vue'
import HeaderTabs from '../components/HeaderTabs.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { JournalTypeItemType } from '#/TransactionsTypes'
import { useTransactionStore } from '@/stores/modules/transactions'
import AccountBalanceScreening from '../components/LedgerScreening.vue'
import JournalTypeFormDialog from './components/JournalTypeFormDialog.vue'

dayjs.extend(utc)
const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const transactionStore = useTransactionStore()
const loading = ref(false)
const headerRef = ref()
const screeningRef = ref()
const journalTypeTableRef = ref()
const journalTypeFormDialogRef = ref()
const journalTypeFormDialogModel = ref('add')
const currentData: any = ref({})

const journalTypeParams = ref({
  page: 1,
  limit: 20
})

const tableHeight = computed(() => {
  return headerRef.value?.isShowRegeneratedInfo ? `calc(100vh - 314px)` : `calc(100vh - 246px)`
})

const entityId = computed(() => {
  return String(route.params.entityId)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const tableData = computed<JournalTypeItemType[]>(() => {
  const journalTypeList = transactionStore.journalTypeList.filter((item: any) => item.slug !== 'general_journal')
  const totalCount = journalTypeList?.length
  if (!totalCount) return []
  const totalPages = Math.ceil(totalCount / journalTypeParams.value.limit)

  // 确保page在有效范围内
  const page = Math.max(1, Math.min(journalTypeParams.value.page, totalPages))

  // 计算当前页的起始索引和结束索引
  const startIndex = (page - 1) * journalTypeParams.value.limit
  const endIndex = Math.min(startIndex + journalTypeParams.value.limit, totalCount)
  return journalTypeList.slice(startIndex, endIndex)
})

const onChangePageSize = (limit: number) => {
  journalTypeParams.value.limit = limit
  journalTypeParams.value.page = 1
  journalTypeTableRef.value.ref?.setScrollTop(0)
  journalTypeTableRef.value.ref?.setScrollLeft(0)
}

const onChangePage = (page: number) => {
  journalTypeParams.value.page = page
  journalTypeTableRef.value.ref?.setScrollTop(0)
  journalTypeTableRef.value.ref?.setScrollLeft(0)
}

const onAddNewJournalType = () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  journalTypeFormDialogModel.value = 'add'
  journalTypeFormDialogRef.value?.onCheckJournalTypeDialog()
}

const onEditJournalType = (row: any) => {
  journalTypeFormDialogModel.value = 'edit'
  currentData.value = row
  nextTick(() => {
    journalTypeFormDialogRef.value?.onCheckJournalTypeDialog()
  })
}

const fetchJournalTypeList = async () => {
  try {
    loading.value = true
    await transactionStore.fetchJournalTypeList(entityId.value)
    journalTypeTableRef.value.ref?.setScrollTop(0)
    journalTypeTableRef.value.ref?.setScrollLeft(0)
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}

watch(
  () => route,
  async () => {
    if (route.name === 'entity-ledger-journal-type') {
      await fetchJournalTypeList()
      entityStore.fetchChartOfAccountList(entityId.value)
    }
  },
  { immediate: true }
)
</script>
<style lang="scss" scoped>
.elv-ledger-journal-type-detail-page {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .elv-ledger-journal-type-detail-content {
    flex: 1;
    min-height: 0;
    position: relative;
  }
}
</style>
